






















import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class GMPanelAppCard extends Vue {
  @Prop() item
  hovered = false

  get borderColor() {
    return this.hovered && !this.item.disabled ? 'border-primary' : 'border-white'
  }
}
